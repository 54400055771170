import React from 'react'
import logo from '../assets/images/logo.png'
import { getDynamicUtmSource } from '../utils/helpers' // Add this import

const NotFound = () => {
    const utmSource = getDynamicUtmSource()
    console.log("utmSource: ", utmSource)

    return (
        <div className='flex flex-col justify-between items-center pt-20 gap-2 h-screen w-screen'>
            <a href={`https://muzify.com/?utm_source=${utmSource}&utm_medium=referral&utm_content=artist_page`} target='_blank' rel="noopener noreferrer">
                <img width="100" height="50" src={logo} alt='logo' />
            </a>
            <div className='flex flex-col justify-center items-center grow text-center gap-2 w-3/4 flex-wrap'>
                <span className='text-[rgba(239,209,101,1)] font-oswald md:text-4xl text-3xl font-bold'>404 <br className='md:hidden'/> PAGE NOT FOUND</span>
                <span className='text-[rgba(184,184,184,1)] font-oswald md:text-lg text-base font-normal font-lato'>Woops. Looks like this page doesn’t exist. Click the button below
                    <br /> to start discovering artists to listen.</span>
                <a className='py-2 px-6 bg-[rgba(239,209,101,1)] rounded-3xl mt-2' href='https://www.asset.money/' target='_blank' rel="noopener noreferrer">Go Home</a>
            </div>
        </div>
    )
}

export default NotFound