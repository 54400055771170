/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { getArtistSongs } from '../endpoints/api'
import Lottie from 'lottie-react';
import Chords from '../assets/styles/chords.json'
import logo from '../assets/images/logo.png'
import { useNavigate, useParams } from 'react-router-dom'
import { createArtistSlug, getDynamicUtmSource } from '../utils/helpers' // Update this import

// const artist_id = 'f90e8b26-9e52-4669-a5c9-e28529c47894'

const ArtistRedirect = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const getArtistData = useCallback(async () => {
        try {
            const data = await getArtistSongs(id);
            // artisst slug
            let result = data.data?.data
            const slug = createArtistSlug(result?.artist_name)
            navigate(`/artist/${slug}/${id}`)
        } catch (error) {
            if (error.response && error.response.status === 404) {
                navigate('/404')
            } else {
                console.error('An error occurred:', error);
            }
        }
    }, [id, navigate]);

    useEffect(() => {
        getArtistData()
    }, [getArtistData])

    const utmSource = getDynamicUtmSource()
    console.log("utmSource: ", utmSource)

    return (
        <div className='flex items-center flex-col pt-10 gap-2' >
            <a href={`https://muzify.com/?utm_source=${utmSource}&utm_medium=referral&utm_content=artist_page`} target='_blank' rel="noopener noreferrer">
                <img width="100" height="50" src={logo} alt='logo' />
            </a>
            {/* <Header/> */}
            <Lottie className=" md:w-96" animationData={Chords} loop={true} />


        </div>
    )
}

export default ArtistRedirect