import React, { useState } from "react";
import VerifyIcon from "../../assets/images/icons8-instagram-verification-badge 1.svg";
import { CiGlobe } from "react-icons/ci";
import { AiOutlineInstagram } from "react-icons/ai";
import { PiSpotifyLogoLight } from "react-icons/pi";
import { SiApplemusic } from "react-icons/si";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import Track from "../../assets/images/Track.png";
import BGRedirect from "../../assets/images/bgRedirect.png";
import SuperfanBG from "../../assets/images/superfan-bg.png";
import youtube from "../../assets/images/youtube.svg";
import Modal from "./Modal";
import { getArtistBio } from "../../utils/helpers";

const SongImage = ({ artistSongs }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const savedArtistBio = getArtistBio(artistSongs.applemusic_artist_id)

  const initialContent = savedArtistBio ? savedArtistBio : artistSongs?.artist_about?.replace(
    /^<p[^>]*>[\s\S]*?<\/p>/,
    ""
  );
  const modifiedInitialContent = initialContent
    ?.replace(/<p class="mw-empty-elt">[\s\S]*?<\/p>/g, "") // Remove empty p tag
    ?.replaceAll(/<\/p>/g, "</p><br>")
    ?.replace(/<br>$/, "");

  var intialData = modifiedInitialContent?.slice(
    0,
    modifiedInitialContent?.indexOf(".", 500) + 1
  );
  var additionalContent = modifiedInitialContent?.slice(
    modifiedInitialContent?.indexOf(".", 500) + 1,
    modifiedInitialContent?.length
  );
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (expanded) {
      intialData = modifiedInitialContent?.slice(
        0,
        modifiedInitialContent?.indexOf(".", 500) + 1
      );
    } else {
      additionalContent = modifiedInitialContent?.slice(
        modifiedInitialContent?.indexOf(".", 500) + 1,
        modifiedInitialContent?.length
      );
      // additionalContent = additionalContent.replaceAll('</p>', '</p><br />')
    }
  };

  const contentStyle = {
    maxHeight: expanded ? "100%" : "210px", // Adjust the initial height as needed
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out",
  };

  console.log(artistSongs?.artist_top_songs);

  return (
    <>
      {/* <img width="100" height="50" src={logo} alt='logo' /> */}
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          artistId={artistSongs?.applemusic_artist_id}
          artistName={artistSongs?.artist_name}
        />
      )}
      <div className="flex md:flex-row flex-col gap-4 justify-center md:mb-0 mb-16 w-full">
        <div className="flex flex-col gap-2 md:justify-start md:pt-14 md:items-start justify-center items-center md:w-[35%]">
          <img
            className="md:ml-9 mt-8 w-32 h-32 md:w-52 md:h-52 object-cover rounded-3xl"
            src={
              artistSongs.artist_image
                ? artistSongs.artist_image
                : "/artist-photo.png"
            }
            alt="artist"
          />
          <div className="flex flex-row gap-2 md:pl-10">
            <p className="text-[rgba(239,209,101,1)] font-oswald text-xl font-[Oswald] uppercase font-bold">
              {artistSongs?.artist_name}
            </p>
            {artistSongs?.artist_asset_verified === true && (
              <img width="25" height="25" src={VerifyIcon} alt="logo" />
            )}
          </div>
          {artistSongs?.artist_total_fans !== 0 ? (
            <p className="text-[rgba(255,255,255,0.7)] font-lato md:pl-10">
              {artistSongs?.artist_total_fans
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              Fans across the world
            </p>
          ) : (
            ""
          )}
          <button
            className="hidden md:flex md:w-[480px]  px-10 h-[260px] gap-3 md:h-[268px] rounded-[14px] flex-row items-center justify-center"
            style={{
              backgroundImage: `url(${SuperfanBG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => setModalOpen(true)}
          >
            <div className="flex flex-col items-center justify-center gap-3">
              <span className="md:text-[30px] font-medium md:leading-7 text-[20px] text-[#F4ECE6]">
                Join {artistSongs?.artist_name}’s
              </span>
              <span className="md:text-[36px] font-extrabold md:leading-7 text-[20px] text-[#F4ECE6]">
                Fan Club
              </span>
              <span
                style={{ color: "rgba(244, 236, 230, 0.7)" }}
                className="md:text-[14px] text-[12px]"
              >
                Sign up for personalized quizzes, tickets, and exclusive artist content!
              </span>
              <button
                onClick={() => setModalOpen(true)}
                className="bg-transparent md:w-[112px] w-[90px] text-white md:text-[16px] text-[11px] h-[28px] md:h-[36px] rounded-[100px] flex items-center justify-center border border-[#FEC330]"
              >
                Sign up!
              </button>
            </div>
          </button>
          {intialData ? (
            <div className="md:block mt-7 hidden">
              <div className="flex flex-col gap-2 px-10 flex-wrap flex-grow-0 justify-start">
                <span className="text-2xl mt-4 mb-2 text-[rgba(255,255,255,1)]">
                  About {artistSongs?.artist_name}
                </span>
                <div style={contentStyle}>
                  <div
                    className="text-white apiContent"
                    dangerouslySetInnerHTML={{ __html: intialData }}
                  />
                </div>
                <CSSTransition
                  in={expanded}
                  timeout={300}
                  classNames="content-animation"
                  className="text-white"
                  unmountOnExit
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: additionalContent }}
                  />
                </CSSTransition>

                <div className="flex flex-row w-full">
                  <button
                    className="text-[rgba(239,209,101,1)]"
                    onClick={toggleExpanded}
                  >
                    {" "}
                    {expanded ? "Show Less" : "Read More"}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="hidden md:block" style={{ width: "450px" }}></div>
          )}

          <div className="hidden md:block mt-2">
            <div className="flex flex-row gap-3 md:pl-10 flex-wrap">
              {artistSongs?.artist_genres.length > 0 &&
                artistSongs?.artist_genres.slice(0, 3).map((genre, i) => (
                  <button
                    className="py-1 px-3 h-10 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white disabled"
                    disabled
                  // onClick={handleSpotifySignIn}
                  >
                    {genre}
                  </button>
                ))}
            </div>
          </div>
          <div className="md:block hidden">
            <div className="flex gap-2 md:pl-10">
              {artistSongs?.artist_social_links?.official_homepage && (
                <a
                  type="button"
                  className="py-2 px-2 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.official_homepage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CiGlobe size={20} />
                </a>
              )}
              {artistSongs?.artist_social_links?.instagram && (
                <a
                  type="button"
                  className="py-2 px-2 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineInstagram size={20} />
                </a>
              )}
              {artistSongs?.artist_social_links?.spotify && (
                <a
                  type="button"
                  className="py-2 px-2 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PiSpotifyLogoLight size={20} />
                </a>
              )}
              {artistSongs?.applemusic_artist_id && (
                <a
                  type="button"
                  className="py-3 px-3 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={`https://music.apple.com/us/artist/${artistSongs?.applemusic_artist_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SiApplemusic size={15} />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="md:px-0 px-4 md:pt-14 mt-8 md:mt-0">
          <div className="flex flex-col gap-2">
            {artistSongs?.artist_upcoming_releases != null && (
              <div>
                <p className="text-lg text-[rgba(255,255,255,1)] font-lato mb-6">
                  Upcoming Releases
                </p>
                {artistSongs?.artist_upcoming_releases.map((genre, index) => (
                  <div className="flex md:gap-5 items-center mb-3">
                    <div className="flex items-center w-[65%]">
                      <span className="text-base text-[rgba(255,255,255,0.7)]">
                        {index + 1}
                      </span>
                      <img
                        width="40"
                        height="40"
                        src={genre.pre_save_content_link}
                        alt="logo"
                        className="ml-5 mx-2 rounded-lg"
                      />
                      <div className="flex flex-col">
                        <span className="md:text-base font-lato text-sm text-white max-w-xs">
                          {genre.pre_save_name}
                        </span>
                      </div>
                    </div>
                    <a
                      className="py-1 px-3 ml-2 md:h-10 h-8 md:text-base flex justify-between flex-nowrap items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm "
                      href={
                        "https://apps.apple.com/in/app/asset-your-music-stats/id6449702693"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiOutlinePlusCircle size={15} className="mr-2" />
                      <span className="whitespace-normal text-sm min-w-fit">
                        {" "}
                        Pre-Save
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            )}
            <button
              className="md:hidden md:w-[470px]  px-10 h-[260px] gap-3 md:h-[268px] flex rounded-[14px] flex-row items-center justify-center"
              style={{
                backgroundImage: `url(${SuperfanBG})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => setModalOpen(true)}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <div className="flex flex-col items-center justify-center">
                  <span className="md:text-[30px] font-medium md:leading-7 text-[30px] text-[#F4ECE6]">
                    Join {artistSongs?.artist_name}’s 
                  </span>
                  <span className="md:text-[36px] font-extrabold md:leading-7 text-[34px] text-[#F4ECE6]">
                  Fan Club
                  </span>
                </div>
                <span
                  style={{ color: "rgba(244, 236, 230, 0.7)" }}
                  className="md:text-[14px] text-[12px]"
                >
                  Sign up for personalized quizzes, tickets, and exclusive artist content!
                </span>
                <button
                  onClick={() => setModalOpen(true)}
                  className="bg-transparent md:w-[112px] w-[90px] text-white md:text-[16px] text-[11px] h-[28px] md:h-[36px] rounded-[100px] flex items-center justify-center border border-[#FEC330]"
                >
                  Sign up!
                </button>
              </div>
            </button>
            {/* <a
              className="md:w-[470px]  px-10 h-[260px] gap-3 md:h-[268px] flex rounded-[14px] flex-row items-center justify-center"
              style={{
                backgroundImage: `url(${BGRedirect})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              href={`https://muzify.com/quiz/${artistSongs?.applemusic_artist_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex flex-col gap-3 md:w-[40%]">
                <span className="md:text-[25px] font-semibold md:leading-7 text-[18px] text-[#F4ECE6]">
                  Are you a true {artistSongs?.artist_name} fan?
                </span>
                <span
                  style={{ color: "rgba(244, 236, 230, 0.7)" }}
                  className="md:text-[10px] text-[8px]"
                >
                  Play the music quiz & show your friends who the biggest fan is
                </span>
                <a
                  className="bg-[#FEC330] md:w-[112px] w-[90px] text-[#141A1E] md:text-[16px] text-[11px] h-[28px] md:h-[36px] rounded-[100px] flex items-center justify-center"
                  href={`https://muzify.com/quiz/${artistSongs?.applemusic_artist_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Play Now!
                </a>
              </div>

              <div className="relative md:w-[210px] md:h-[260px]">
                <img
                  src={Track}
                  className="md:w-full w-[310px] h-[200px] md:h-full"
                  alt="track"
                />
                <span
                  className="absolute text-[8px] sm:text-[10px] md:text-[12px] text-[#FEC330]"
                  style={{
                    top: "20%", // smaller on mobile
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {artistSongs?.artist_name}
                </span>
              </div>
            </a> */}

            {artistSongs.artist_top_songs.length > 0 && (
              <div className="mt-10">
                <span className="text-lg md:pl-0 ml-10 text-[rgba(255,255,255,1)] font-lato pt-20 mb-6 text-left">
                  Popular Songs
                </span>
                {artistSongs.artist_top_songs.map((song, index) => (
                  <div className="flex md:px-0 px-4  md:gap-5 items-center mb-3 mt-6">
                    <div className="flex items-center w-[65%]">
                      <span className="md:text-base text-sm text-[rgba(255,255,255,0.7)]">
                        {index + 1}
                      </span>
                      <img
                        width="40"
                        height="40"
                        src={song.song_image}
                        alt="logo"
                        className="ml-5 mx-2 rounded-lg"
                      />
                      <span className="md:text-base text-sm font-lato text-white max-w-xs">
                        {song.song}
                      </span>
                    </div>
                    <a
                      className="py-1 px-4 ml-2 md:h-10 h-8 md:text-base flex justify-center flex-nowrap items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm "
                      href={song?.youtube_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <AiOutlinePlusCircle size={15} style={{ 'minWidth': '15px' }} className='mr-2' /> */}
                      <img
                        src={youtube}
                        alt="youtube"
                        width="20"
                        height="20"
                        className="mr-2"
                      />
                      <span className="whitespace-normal min-w-fit text-sm">
                        Listen
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            )}
            {/* <a
              className="fixed md:bottom-0 bottom-8 right-0 md:right-0 m-4 py-1 px-5 h-10 flex items-center rounded-full bg-[rgba(239,209,101,1)] border border-[rgba(56,56,56,1)] text-[rgba(30,34,40,1)] text-sm"
              href="https://tally.so#tally-open=n0Bq5B&tally-layout=modal&tally-emoji-text=👋&tally-emoji-animation=wave"
            >
              Claim your artist<span id="emojiWave"> 👋🏻</span>
            </a> */}
          </div>

          <div className="md:hidden px-5 pt-4">
            {initialContent && (
              <div className="flex flex-col gap-2">
                <span className="text-lg text-[rgba(255,255,255,1)]">
                  About {artistSongs?.artist_name}
                </span>
                <div style={contentStyle}>
                  <div
                    className="text-white apiContent"
                    dangerouslySetInnerHTML={{ __html: intialData }}
                  />
                </div>
                <CSSTransition
                  in={expanded}
                  timeout={300}
                  classNames="content-animation"
                  className="text-white"
                  unmountOnExit
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: additionalContent }}
                  />
                </CSSTransition>
                <div className="flex flex-row w-full">
                  <button
                    className="text-[rgba(239,209,101,1)]"
                    onClick={toggleExpanded}
                  >
                    {" "}
                    {expanded ? "Show Less" : "Read More"}
                  </button>
                </div>
              </div>
            )}

            <div className="flex gap-2 py-4 flex-wrap justify-center items-center">
              {artistSongs?.artist_genres.length > 0 &&
                artistSongs?.artist_genres.slice(0, 3).map((genre, i) => (
                  <button
                    type="button"
                    className="py-1 px-3 h-10 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white disabled"
                    disabled
                  // onClick={handleSpotifySignIn}
                  >
                    {genre}
                  </button>
                ))}
            </div>
            <div className="flex flex-row justify-center items-center gap-2">
              {artistSongs?.artist_social_links?.official_homepage && (
                <a
                  className="py-2 px-2 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.official_homepage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CiGlobe size={20} />
                </a>
              )}
              {artistSongs?.artist_social_links?.instagram && (
                <a
                  className="py-2 px-2 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineInstagram size={20} />
                </a>
              )}
              {artistSongs?.artist_social_links?.spotify && (
                <a
                  className="py-2 px-2 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PiSpotifyLogoLight size={20} />
                </a>
              )}
              {artistSongs?.artist_social_links?.apple_music && (
                <a
                  type="button"
                  className="py-3 px-3 flex items-center rounded-full bg-[rgba(13,22,40,1)] border border-[rgba(56,56,56,1)] text-[rgba(255,255,255,0.7)] text-sm text-white"
                  href={artistSongs?.artist_social_links?.apple_music}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SiApplemusic size={15} />
                </a>
              )}
            </div>
          </div>
        </div>
        {/* <div className='flex flex-col justify-end'>


        </div> */}
      </div>
    </>
  );
};

export default SongImage;
