import axios from "axios";

const BASE_URI = process.env.REACT_APP_ASSET_API;

export const getArtistSongs = (artist_id, ) => {
    // const config = {
    //     headers: {
    //         Authorization: token,
    //         "Content-Type": "application/json",
    //     }
    // }
    return axios.get(`${BASE_URI}/artist/music/${artist_id}`)
};