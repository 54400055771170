import ArtistPageData from './screens/ArtistData';
import React from 'react'

import {
  Route,
  Routes,
} from "react-router-dom";
import { FirebaseAuthProvider } from './context/authContext';
import NotFound from './components/NotFound';
import Homepage from './screens/Homepage';
import ArtistRedirect from './screens/ArtistRedirect';

function App() {

  console.log("HELLO ")

  return (
    <FirebaseAuthProvider>
      <Routes>
        <Route index path='/' element={<NotFound />} />
        <Route index path='/artist/:id' element={<ArtistRedirect />} />
        <Route index path='/artist/:slug/:id' element={<ArtistPageData />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </FirebaseAuthProvider>
  );
}

export default App;
