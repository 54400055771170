import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";

const config = {
    apiKey: 'AIzaSyBn6Z25L5AQw8UFoIFdVqUneidEH_YX97E',
    authDomain: 'asset-9c461.firebaseapp.com',
    projectId: 'asset-9c461',
    appId: '1:732725049341:web:a1a8236c8c52f4d8df8b94',
    measurementId: 'YOUR_MEASUREMENT_ID',    
};

export const app = initializeApp(config);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);